import React from 'react';
import { useMsal } from '@azure/msal-react';
import { DropdownButton } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/esm/Dropdown';

export default function SignOutButton() {
  const { instance } = useMsal();

  const handleLogout = (logoutType:string) => {
    if (logoutType === 'popup') {
      instance.logoutPopup({
        postLogoutRedirectUri: '/',
      });
    } else if (logoutType === 'redirect') {
      instance.logoutRedirect({
        postLogoutRedirectUri: '/',
      });
    }
  };
  return (
    <DropdownButton variant="secondary" className="ml-auto text-right" drop="start" align="end" title="Sign Out">
      <Dropdown.Item as="button" onClick={() => handleLogout('popup')}>Sign out using Popup</Dropdown.Item>
      <Dropdown.Item as="button" onClick={() => handleLogout('redirect')}>Sign out using Redirect</Dropdown.Item>
    </DropdownButton>
  );
}
