import React, { useIsAuthenticated } from '@azure/msal-react';
import sendAnalyticsPageViewEvent from '../../shared/analytics/TagManagerHelper';

export default function Events() {
  sendAnalyticsPageViewEvent('EventsPage');

  const isAuthenticated = useIsAuthenticated();
  const user = {
    username: 'get username from MSAL',
  };
  // throw Error('Fake error');
  return (

    <div style={{ margin: 'auto', border: '4px solid green' }}>
      <p>This is a Public page</p>
      {isAuthenticated
        ? (
          <>
            {' '}
            Authenticated User:
            { user.username}
            {' '}

          </>
        )
        : <>User is not authenticated</>}

    </div>

  );
}
