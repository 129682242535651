import React from 'react';
import { DropdownButton } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/esm/Dropdown';
import { useAppContext } from '../context/AppContextProvider';

/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 */
export default function SignInButton() {
  // const { instance } = useMsal();
  const {
    appConfig,
    msalClient,
    showToastMessage,
    setIdToken,
  } = useAppContext();

  const handleLogin = async (loginType: string) => {
    if (loginType === 'popup') {
      const authResult = await msalClient.loginPopup(appConfig.loginRequest).catch(async (e) => {
        if (e.errorMessage.indexOf('AADB2C90118') > -1) {
          await msalClient.loginPopup(appConfig.forgotPasswordRequest).catch((e2) => {
            const err = JSON.stringify(e2);
            showToastMessage(`Forgot Password Popup failed with error ${err}.`);
          });
        } else {
          const err = JSON.stringify(e);
          showToastMessage(`Login Popup failed with error ${err}.`);
        }
      });
      if (authResult != null) {
        setIdToken(authResult.idToken);
      }
    } else if (loginType === 'redirect') {
    // redirect does not provide AuthenticationResult
      await msalClient.loginRedirect(appConfig.loginRequest2).catch((e) => {
        const err = JSON.stringify(e);
        showToastMessage(`Login Redirect failed with error ${err}.  You may need to log out and restart the app.`);
      });
    }
  };
  return (
    <DropdownButton variant="secondary" className="ml-auto text-right" drop="start" align="end" title="Sign In">
      <Dropdown.Item as="button" onClick={() => handleLogin('popup')}>Sign in using Popup</Dropdown.Item>
      <Dropdown.Item as="button" onClick={() => handleLogin('redirect')}>Sign in using Redirect</Dropdown.Item>
    </DropdownButton>
  );
}
