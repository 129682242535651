import React, { useState, useEffect, useReducer } from 'react';
import './CustomerList.css';
import {
  Col, Card, Navbar, Button, Form, Row,
} from 'react-bootstrap';
import useInput from '../../shared/forms/useInput';
import { IPerson, IPersonWithSyncStatus } from './IPerson';
import { customerDb } from './CustomerDB';
import { IClassCode } from './IClassCode';
import { useAppContext, BrowserSizeEnum } from '../../shared/context/AppContextProvider';
import Spinner from '../../shared/Spinner/Spinner';
import { FetchMethod } from '../../shared/http/Fetch';
import sendAnalyticsPageViewEvent from '../../shared/analytics/TagManagerHelper';

function SpinnerX() {
  return (
    <div style={{
      position: 'absolute', zIndex: 9999, marginLeft: '50%', marginTop: 100,
    }}
    >
      <Spinner />
    </div>
  );
}

// Hook-based form.  Ref blog: https://rangle.io/blog/simplifying-controlled-inputs-with-hooks/
export default function CustomerList() {
  sendAnalyticsPageViewEvent('CustomerEntryPage');

  const [loading, setLoading] = useState<boolean>(true);
  const { value: firstName, bind: bindFirstName, reset: resetFirstName } = useInput('');
  const { value: lastName, bind: bindLastName, reset: resetLastName } = useInput('');
  const { value: classCodeId, bind: bindClassCodeId, reset: resetClassCodeId } = useInput('2');
  const [formSubmitTime, setFormSubmitTime] = useState<number>(0);
  const [classCodes, setClassCodes] = useState<Array<IClassCode>>([]);
  const {
    appConfig, showToastMessage, browserSize, fetchSecure,
  } = useAppContext();

  function syncReducer(state: Array<string>, action: { type: string, payload: string }) {
    switch (action.type) {
      case 'reset':
        return [];
      case 'add':
        return state.concat(action.payload); // or return [...state, action.payload];
      default:
        throw new Error();
    }
  }

  function peoplesyncWStatusReducer(
    state: Array<IPersonWithSyncStatus>,
    action: { type: string, payload: IPersonWithSyncStatus | null },
  ) {
    switch (action.type) {
      case 'reset':
        return [];
      case 'add':
        if (action.payload) {
          return state.concat(action.payload); // or return [...state, action.payload];
        }
        return [...state];

      default:
        throw new Error();
    }
  }

  const [syncresults, syncDispatch] = useReducer(syncReducer, []);
  const [peopleWithSyncStatus, syncWithStatusDispatch] = useReducer(peoplesyncWStatusReducer, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        syncWithStatusDispatch({ type: 'reset', payload: null });
        customerDb.getAllCustomers().then(async (personsFromDB) => {
          await personsFromDB.map(async (p) => {
            const personWithSyncStatus: IPersonWithSyncStatus = {
              id: p.id,
              firstname: p.firstname,
              lastname: p.lastname,
              NeedSync: p.needsync,
            };
            if (classCodes?.length > 0 && p.classcodeid > 0) {
              const filtered = classCodes.filter((cc) => cc.classCodeId === p.classcodeid);
              if (filtered?.length > 0) {
                const cc = filtered[0];
                personWithSyncStatus.classcodeDescription = cc.description;
              }
            }
            syncWithStatusDispatch({ type: 'add', payload: personWithSyncStatus });
          });
        });
        setLoading(false);
      } catch (err) {
        showToastMessage(`Error getting people from IndexedDb ${err}`);
      }
    };
    if (classCodes?.length > 0) {
      fetchData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formSubmitTime, classCodes]);

  useEffect(() => {
    const fetchClassCodes = async () => {
      const endpoint = `${appConfig.apiEndpoint}/api/Customer/ClassCodes`;
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      const options = {
        method: 'GET',
        headers,
      };
      await fetch(endpoint, options)
        .then((r) => r.json())
        .then((data) => {
          setClassCodes(data);
        })
        .catch((error) => {
          showToastMessage(`ERROR fetching Class Codes:${error}`);
          setLoading(false);
          return error;
        });
    };
    fetchClassCodes();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const postCustomer = async (data: IPerson): Promise<Response | null> => {
    const result = await fetchSecure(FetchMethod.post, '/api/Customer/Person', data);
    return result;
  };

  const handleSubmit = async (evt: React.SyntheticEvent<EventTarget>) => {
    evt.preventDefault();
    setFormSubmitTime(new Date().getTime());
    await customerDb.addCustomer({
      firstname: firstName,
      lastname: lastName,
      classcodeid: parseInt(classCodeId, 10),
      needsync: false,
    });
    resetFirstName();
    resetLastName();
    resetClassCodeId();
  };

  const addToResults = (entry: string) => {
    syncDispatch({ type: 'add', payload: entry });
  };
  const SyncData = async () => {
    syncDispatch({ type: 'reset', payload: '' });
    addToResults("Checking for unsync'd people");

    const syncs = await customerDb.customersNeedSyncd();
    addToResults(`There are ${syncs.length} people to sync`);

    let numberOfSuccess = 0;
    let numberOfError = 0;
    syncs.forEach(async (s, idx) => {
      if (s.id == null) {
        // console.error('Sync error: sync person has a null or undefined id');
        return;
      }
      const person: IPerson | undefined = await customerDb.getCustomerById(s.id);
      if (person === undefined) {
        // console.log('Sync error unable to find person id {s.id} in storage, unable post sync');
        return;
      }
      try {
        // console.log(`synchronizing Person:${JSON.stringify(person)}`);
        const response = await postCustomer(person);
        if (response !== null) {
          // console.log(`response=${JSON.stringify(response));
          if (response.status < 300) {
            await customerDb.setSyncStatus(s.id);
            addToResults(`...successfully posted ${person.id} : ${person.firstname}  ${person.lastname} to Api`);
            numberOfSuccess += 1;
          } else {
            const errtext = await response.text();
            addToResults(`Api failed for ${person?.id} : ${person?.firstname} ${person?.lastname} , returning response=${errtext}`);
            numberOfError += 1;
          }
        }
      } catch (err) {
        addToResults(`Error synchronizing person ${person.id}, err=${JSON.stringify(err)}`);
        numberOfError += 1;
      }
      if (idx === (syncs.length - 1)) {
        showToastMessage(`There were ${numberOfError} errors and ${numberOfSuccess} successful saves.  Please review Sync message for details.`);
        setFormSubmitTime(new Date().getTime());
      }
    });
  };

  const formcontent = () => {
    if (browserSize === BrowserSizeEnum.large) {
      return (
        <>
          <Row>
            <Form.Group as={Col} controlId="firstname">
              <Form.Label>First Name</Form.Label>
              <Form.Control type="text" placeholder="First Name" {...bindFirstName} />
            </Form.Group>
            <Form.Group as={Col} controlId="lastname">
              <Form.Label>Last Name</Form.Label>
              <Form.Control type="text" placeholder="Last Name" {...bindLastName} />
            </Form.Group>
            <Form.Group as={Col} controlId="classcode">
              <Form.Label>Class Code</Form.Label>
              <Form.Control as="select" {...bindClassCodeId}>
                {classCodes.map((cc) => (
                  <option key={cc.classCodeId} value={cc.classCodeId}>
                    {cc.description}
                    {' '}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Row>
          <Row style={{ margin: 20 }}>
            <Col sm={{ span: 10, offset: 10 }}>
              <Button id="submitbutton" variant="primary" type="submit">Save Local</Button>
            </Col>
          </Row>
        </>
      );
    }
    return (
      <>
        <Form.Group controlId="firstname" style={{ margin: 10 }}>
          <Form.Label>First Name</Form.Label>
          <Form.Control type="text" placeholder="First Name" {...bindFirstName} />
        </Form.Group>
        <Form.Group controlId="lastname">
          <Form.Label>Last Name</Form.Label>
          <Form.Control type="text" placeholder="Last Name" {...bindLastName} />
        </Form.Group>
        <Form.Group controlId="classcode">
          <Form.Label>Class Code</Form.Label>
          <Form.Control as="select" {...bindClassCodeId}>
            {classCodes.map((cc) => (
              <option key={cc.classCodeId} value={cc.classCodeId}>
                {cc.description}
                {' '}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Button variant="primary" type="submit" style={{ margin: 20 }}>Save Local</Button>
      </>
    );
  };

  const peoplelist = () => {
    if (browserSize === BrowserSizeEnum.large) {
      return (
        <div style={{ margin: 'auto', width: '50%' }}>
          <table style={{ width: '500px' }}>
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Class Code</th>
                <th>Needs Sync&apos;d</th>
              </tr>
            </thead>
            <tbody>
              {peopleWithSyncStatus?.map((person) => (
                <tr key={person.id}>
                  <td>{person.firstname}</td>
                  <td>{person.lastname}</td>
                  <td>{person.classcodeDescription}</td>
                  <td>{person.NeedSync ? '*' : ''}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }
    return (
      <div>
        {peopleWithSyncStatus?.map((person) => (
          <Card style={{ width: '18rem', margin: 10 }}>
            <Card.Body>
              <Card.Title>
                {person.firstname}
&nbsp;
                {person.lastname}
              </Card.Title>
              <Card.Subtitle className="mb-2 text-muted">{person.classcodeDescription}</Card.Subtitle>
              <Card.Text>
                {person.NeedSync ? 'Needs synchronized' : ':)'}
              </Card.Text>
            </Card.Body>
          </Card>
        ))}
      </div>
    );
  };

  return (
    <>

      <Navbar bg="primary" variant="dark">
        <Button variant="secondary" onClick={SyncData}>Sync Data</Button>
      </Navbar>
      {loading === true ? <SpinnerX /> : null}
      <Form onSubmit={handleSubmit}>
        {formcontent()}
      </Form>
      <h3>Customer List</h3>
      {peoplelist()}

      <div>
        <h3>Sync Results</h3>
        <ul>
          {syncresults?.map((sr: string, i: number) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={i}>{sr}</li>
          ))}
        </ul>
      </div>
    </>
  );
}
