import React, { useState } from 'react';
// import { useAppContext } from '../context/AppContextProvider';

export default function useInput(initialValue: string) {
  const [value, setValue] = useState(initialValue);

  // const { showToastMessage } = useAppContext();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const userValue: string = event.target.value;
    setValue(userValue);
    // showToastMessage(userValue);
  };

  return {
    value,
    setValue,
    reset: () => setValue(''),
    bind: {
      value,
      onChange: handleChange,
    },
  };
}
