import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'; // withAITracking
import { createBrowserHistory } from 'history';

const browserHistory = createBrowserHistory();
export const reactPlugin = new ReactPlugin();
export const appInsights = new ApplicationInsights({
  config: {
    // eslint-disable-next-line max-len
    connectionString: 'InstrumentationKey=dda1d51b-de55-42d7-8cf6-f6d922467c08;IngestionEndpoint=https://centralus-2.in.applicationinsights.azure.com/;LiveEndpoint=https://centralus.livediagnostics.monitor.azure.com/',
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});
appInsights.loadAppInsights();
