import React, { useState, useEffect, useReducer } from 'react';
import './CustomerList.css';
import { Card } from 'react-bootstrap';
import { customerDb } from './CustomerDB';
import { useAppContext, BrowserSizeEnum } from '../../shared/context/AppContextProvider';
import Spinner from '../../shared/Spinner/Spinner';
import { IPersonWithSyncStatus } from './IPerson';
import { IClassCode } from './IClassCode';
// import sendAnalyticsPageViewEvent from '../../shared/analytics/TagManagerHelper';

function SpinnerX() {
  return (
    <div style={{
      position: 'absolute', zIndex: 9999, marginLeft: '50%', marginTop: 100,
    }}
    >
      <Spinner />
    </div>
  );
}

interface Props {
  formSubmitTime: number,
  classCodes: Array<IClassCode>,
}

// Hook-based form.  Ref blog: https://rangle.io/blog/simplifying-controlled-inputs-with-hooks/
export default function PeopleList({ formSubmitTime, classCodes }: Props) {
  const [loading, setLoading] = useState<boolean>(true);
  const {
    showToastMessage, browserSize, // fetchUnsecure,
  } = useAppContext();

  function peoplesyncWStatusReducer(
    state: Array<IPersonWithSyncStatus>,
    action: { type: string, payload: IPersonWithSyncStatus | null },
  ) {
    switch (action.type) {
      case 'reset':
        return [];
      case 'add':
        if (action.payload) {
          return state.concat(action.payload); // or return [...state, action.payload];
        }
        return [...state];

      default:
        throw new Error();
    }
  }
  const [peopleWithSyncStatus, syncWithStatusDispatch] = useReducer(peoplesyncWStatusReducer, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        syncWithStatusDispatch({ type: 'reset', payload: null });
        customerDb.getAllCustomers().then(async (personsFromDB) => {
          await personsFromDB.map(async (p) => {
            const personWithSyncStatus: IPersonWithSyncStatus = {
              id: p.id,
              firstname: p.firstname,
              lastname: p.lastname,
              NeedSync: p.needsync,
            };
            if (classCodes?.length > 0 && p.classcodeid > 0) {
              const filtered = classCodes.filter((cc) => cc.classCodeId === p.classcodeid);
              if (filtered?.length > 0) {
                const cc = filtered[0];
                personWithSyncStatus.classcodeDescription = cc.description;
              }
            }
            syncWithStatusDispatch({ type: 'add', payload: personWithSyncStatus });
          });
        });
        setLoading(false);
      } catch (err) {
        showToastMessage(`Error getting people from IndexedDb ${err}`);
      }
    };
    if (classCodes?.length > 0) {
      fetchData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formSubmitTime, classCodes]);

  const peoplelist = () => {
    if (browserSize === BrowserSizeEnum.large) {
      return (
        <div style={{ margin: 'auto', width: '50%' }}>
          <table style={{ width: '500px' }}>
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Class Code</th>
                <th>Needs Sync&apos;d</th>
              </tr>
            </thead>
            <tbody>
              {peopleWithSyncStatus?.map((person) => (
                <tr key={person.id}>
                  <td>{person.firstname}</td>
                  <td>{person.lastname}</td>
                  <td>{person.classcodeDescription}</td>
                  <td>{person.NeedSync ? '*' : ''}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }
    return (
      <div>
        {peopleWithSyncStatus?.map((person) => (
          <Card style={{ width: '18rem', margin: 10 }}>
            <Card.Body>
              <Card.Title>
                {person.firstname}
&nbsp;
                {person.lastname}
              </Card.Title>
              <Card.Subtitle className="mb-2 text-muted">{person.classcodeDescription}</Card.Subtitle>
              <Card.Text>
                {person.NeedSync ? 'Needs synchronized' : ':)'}
              </Card.Text>
            </Card.Body>
          </Card>
        ))}
      </div>
    );
  };

  return (
    <>
      {loading === true ? <SpinnerX /> : null}
      <h3>Customer List</h3>
      {peoplelist()}
    </>
  );
}
