import React from 'react';
import './CustomerList.css';
import {
  Col, Button, Form, Row,
} from 'react-bootstrap';
import useInput from '../../shared/forms/useInput';
import { customerDb } from './CustomerDB';
import { IClassCode } from './IClassCode';
import { useAppContext, BrowserSizeEnum } from '../../shared/context/AppContextProvider';
import sendAnalyticsPageViewEvent from '../../shared/analytics/TagManagerHelper';

interface Props {
  setFormSubmitTime: (newTime: number) => void,
  classCodes: Array<IClassCode>,
}

// Hook-based form.  Ref blog: https://rangle.io/blog/simplifying-controlled-inputs-with-hooks/
export default function CustomerForm({ setFormSubmitTime, classCodes }: Props) {
  sendAnalyticsPageViewEvent('CustomerEntryPage');

  const { value: firstName, bind: bindFirstName, reset: resetFirstName } = useInput('');
  const { value: lastName, bind: bindLastName, reset: resetLastName } = useInput('');
  const { value: classCodeId, bind: bindClassCodeId, reset: resetClassCodeId } = useInput('2');
  const { browserSize } = useAppContext();

  const handleSubmit = async (evt: React.SyntheticEvent<EventTarget>) => {
    evt.preventDefault();
    await customerDb.addCustomer({
      firstname: firstName,
      lastname: lastName,
      classcodeid: parseInt(classCodeId, 10),
      needsync: false,
    });
    setFormSubmitTime(new Date().getTime());
    resetFirstName();
    resetLastName();
    resetClassCodeId();
  };

  const formcontent = () => {
    if (browserSize === BrowserSizeEnum.large) {
      return (
        <>
          <Row>
            <Form.Group as={Col} controlId="firstname">
              <Form.Label>First Name</Form.Label>
              <Form.Control type="text" placeholder="First Name" {...bindFirstName} />
            </Form.Group>
            <Form.Group as={Col} controlId="lastname">
              <Form.Label>Last Name</Form.Label>
              <Form.Control type="text" placeholder="Last Name" {...bindLastName} />
            </Form.Group>
            <Form.Group as={Col} controlId="classcode">
              <Form.Label>Class Code</Form.Label>
              <Form.Control as="select" {...bindClassCodeId}>
                {classCodes.map((cc) => (
                  <option key={cc.classCodeId} value={cc.classCodeId}>
                    {cc.description}
                    {' '}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Row>
          <Row style={{ margin: 20 }}>
            <Col sm={{ span: 10, offset: 10 }}>
              <Button id="submitbutton" variant="primary" type="submit">Save Local</Button>
            </Col>
          </Row>
        </>
      );
    }
    return (
      <>
        <Form.Group controlId="firstname" style={{ margin: 10 }}>
          <Form.Label>First Name</Form.Label>
          <Form.Control type="text" placeholder="First Name" {...bindFirstName} />
        </Form.Group>
        <Form.Group controlId="lastname">
          <Form.Label>Last Name</Form.Label>
          <Form.Control type="text" placeholder="Last Name" {...bindLastName} />
        </Form.Group>
        <Form.Group controlId="classcode">
          <Form.Label>Class Code</Form.Label>
          <Form.Control as="select" {...bindClassCodeId}>
            {classCodes.map((cc) => (
              <option key={cc.classCodeId} value={cc.classCodeId}>
                {cc.description}
                {' '}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Button variant="primary" type="submit" style={{ margin: 20 }}>Save Local</Button>
      </>
    );
  };

  return (
    <>
      <h3>New Customer</h3>
      <Form onSubmit={handleSubmit}>
        {formcontent()}
      </Form>
    </>
  );
}
