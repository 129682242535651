import React, { useEffect, useState } from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated } from '@azure/msal-react';
import { Form, Button, Collapse } from 'react-bootstrap';
import { useAppInsightsContext, useTrackMetric } from '@microsoft/applicationinsights-react-js';
import { useAppContext } from '../../shared/context/AppContextProvider';
import { fetchUnsecure, FetchMethod } from '../../shared/http/Fetch';
import sendAnalyticsPageViewEvent from '../../shared/analytics/TagManagerHelper';

export default function Home() {
  sendAnalyticsPageViewEvent('HomePage');
  const {
    msalClient,
    appConfig,
    getToken,
    idToken,
  } = useAppContext();
  const isAuthenticated = useIsAuthenticated();
  const [token, setToken] = useState('');
  const [datestate, setDatestate] = useState<string>('Submit Dates');
  const [open, setOpen] = useState(false);
  const appInsights = useAppInsightsContext();
  const trackComponent = useTrackMetric(appInsights, 'Home');

  useEffect(() => {
    const init = async () => {
      if (!open) {
        return;
      }
      const accounts = msalClient.getAllAccounts();

      if (accounts?.length > 0) {
        try {
          const tempToken = await getToken();
          if (tempToken) {
            setToken(tempToken.accessToken);
          }
        } catch (err) {
          setToken('error retrieving token - try unblocking popups for this app, or sign out and sign back in.');
        }
      }
    };
    init();
  }, [isAuthenticated, getToken, msalClient, open]);

  const handleSubmit = async (evt: React.SyntheticEvent<EventTarget>) => {
    evt.preventDefault();
    trackComponent();
    const response = await fetchUnsecure(appConfig, FetchMethod.post, '/api/Customer/PostJsDate', { jsDate: new Date(), jsDTO: new Date() });
    if (response) {
      setDatestate(JSON.stringify(response));
    }
  };

  return (
    <>
      <h1>HOME</h1>
      <ul style={{ background: 'lightgrey' }}>
        <li> ADB2C IAM </li>
        <li> Browser resize / re-render support </li>
        <li> Deep-linking (bookmarked link to a routed page works) </li>
        <li> Custom ADB2C Policy adds &quot;Emails&quot; claim needed by MSAL v2.0 (github issue  2654) </li>
        <li> API Scopes granted in access token (github issue) </li>
        <li> Able to call secure API with access token </li>
        <li> based on Create React App, but AppConfig can be updated in pipeline deployment </li>
        <li>PWA with Offline Featrues including caching Api results, Background-Sync, b2clogin metadata results cache</li>
      </ul>

      <AuthenticatedTemplate>
        <>
          <Button
            onClick={() => setOpen(!open)}
            aria-controls="example-collapse-text"
            aria-expanded={open}
          >
            Show me my OAuth Access Token
          </Button>
          <Collapse in={open}>
            <div style={{ margin: '10px 20px' }}>
              Your highly secret OAuth Acess Token is:
              {' '}
              <br />

              {token}
              <div>
                Your IdToken:
                <br />
                {idToken}
              </div>
            </div>
          </Collapse>
        </>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        You are not authenticated!
      </UnauthenticatedTemplate>

      <Form onSubmit={handleSubmit}>
        <Button id="datebutton" variant="primary" type="submit" style={{ margin: 20 }}>Submit</Button>
      </Form>

      {datestate}
    </>
  );
}
