import React from 'react';
import { Toast } from 'react-bootstrap'; // Navbar was imported here
// import { useIsAuthenticated } from '@azure/msal-react';
import { useAppContext } from '../../shared/context/AppContextProvider';
// import SignInButton from '../../shared/forms/SignInButton';
// import SignOutButton from '../../shared/forms/SignOutButton';
import officemikelogo from './officemike-logo-tiny.jpg';

export default function AppHeader() {
  // const isAuthenticated = useIsAuthenticated();
  const { toastMessages, closeToast } = useAppContext();

  const closeMessage = (i: number) => {
    closeToast(i);
  };
  return (
    <div style={{
      position: 'absolute', zIndex: 9999, backgroundColor: 'beige', width: 300,
    }}
    >
      {toastMessages.map((toastMessage: string, idx: number) => (
        // eslint-disable-next-line react/no-array-index-key
        <Toast onClose={(): void => closeMessage(idx)} key={idx} animation style={{ backgroundColor: 'pink', width: '100%' }}>
          <Toast.Header>
            <img src={officemikelogo} alt="Company logo icon" width="20" />
            <strong className="mr-auto" />
          </Toast.Header>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      ))}
    </div>
  );
}
