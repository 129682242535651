import React, {
  useState, useEffect, useContext, useReducer,
} from 'react';
import { MsalProvider } from '@azure/msal-react';
import { AuthenticationResult, PublicClientApplication } from '@azure/msal-browser';
import { IAppContext } from './IAppContext';
import { IAppConfig } from './IAppConfig';
import {
  fetchSecure, fetchUnsecure, FetchMethod, getToken,
} from '../http/Fetch';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const appglobal = window as any;
const msalInstance = new PublicClientApplication(appglobal.AppConfig.msalConfig);

enum ToastActionKind {
  reset = 'reset',
  add = 'add',
  splice = 'splice',
}
type ToastState = Array<string>;

type ToastAction = {
  type: ToastActionKind,
  message?: string | null,
  indexPosition?: number | null
};

export enum BrowserSizeEnum {
  small = 'small',
  large = 'large',
}

const AppContext = React.createContext<IAppContext>({} as IAppContext);
export const useAppContext = () => useContext(AppContext);

export function AppContextProvider(props: { children: React.ReactNode }) {
  const [browserSize, setBrowserSize] = useState<BrowserSizeEnum>(BrowserSizeEnum.large);
  // const [mql, setMql] = useState<MediaQueryList>({} as MediaQueryList);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [appConfig] = useState<IAppConfig>((window as any).AppConfig);
  const [idToken, setIdToken] = useState<string>('');
  function toastReducer(state: ToastState, action: ToastAction): ToastState {
    switch (action.type) {
      case 'reset':
        return [];
      case 'add':
        return state.concat(action.message ?? '--NO-MESSAGE-PROVIDED--'); // or return [...state, action.payload];
      case 'splice':
        // state.splice(action.indexPosition,1);
        // return [...state]
        return state.filter((m, i) => i !== action.indexPosition);
      default:
        throw new Error();
    }
  }
  const [toastMessages, toastDispatch] = useReducer(toastReducer, []);
  const closeToast = (idx: number) => {
    toastDispatch({ type: ToastActionKind.splice, indexPosition: idx });
  };
  const showToastMessage = (msg: string) => {
    toastDispatch({ type: ToastActionKind.add, message: msg });
  };

  useEffect(() => {
    const mediaChange = (mediaQueryListEvent: { matches: boolean, media: string }) => {
      if (mediaQueryListEvent.matches) {
        setBrowserSize(BrowserSizeEnum.small);
      } else {
        setBrowserSize(BrowserSizeEnum.large);
      }
    };
    const effectMql = window.matchMedia('(max-width: 767px)');
    mediaChange(effectMql);
    // setMql(effectMql);
    effectMql.addEventListener('change', mediaChange);
    // return () => {
    //   if (mql != null) {
    //     mql.removeEventListener('change', mediaChange);
    //   }
    // };
    // xxxeslint-disable-next-line react-hooks/exhaustive-deps
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // empty array so this useEffect runs only on initial render

  // provided methos to send requests and get OAuth token, allow changing OAuth library without affecting client code everywhere:
  const getTokenInternal = async (): Promise<AuthenticationResult | undefined> => getToken(msalInstance, appConfig);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fetchSecureInternal = async (method: FetchMethod, relativeEndpoint: string, body?: any): Promise<Response | null> => {
    try {
      const result = await fetchSecure(
        msalInstance,
        appConfig,
        method,
        relativeEndpoint,
        body,
      );
      return result;
    } catch (err) {
      showToastMessage(`User may need to re-login.  This can happen when tokens expire or client url changes.  Error=${err}`);
    }
    return null;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fetchUnsecureInternal = async (method: FetchMethod, relativeEndpoint: string, body?: any): Promise<Response | null> => fetchUnsecure(
    appConfig,
    method,
    relativeEndpoint,
    body,
  );

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const appContext: IAppContext = {
    msalClient: msalInstance,
    browserSize,
    appConfig,
    toastMessages,
    showToastMessage,
    closeToast,
    fetchSecure: fetchSecureInternal,
    fetchUnsecure: fetchUnsecureInternal,
    getToken: getTokenInternal,
    idToken,
    setIdToken,
  };
  const { children } = props;
  return (
    <MsalProvider instance={msalInstance}>
      <AppContext.Provider value={appContext}>
        {children}
      </AppContext.Provider>
    </MsalProvider>
  );
}
