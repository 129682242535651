import TagManager from 'react-gtm-module';

export default function sendAnalyticsPageViewEvent(title: string) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const w = window as any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dl = w.dataLayer as any;
  dl.push({
    event: 'pageview',
    url: w.location,
    title,
  });

  const tagManagerArgs = {
    dataLayer: {
      event: 'pageview',
      page: {
        title,
      },
    },
    // dataLayerName: 'PageDataLayer'
  };

  TagManager.dataLayer(tagManagerArgs);
}
