import React, { useState, useEffect, useReducer } from 'react';
import './CustomerList.css';
import { Navbar, Button } from 'react-bootstrap';
import { IPerson } from './IPerson';
import { customerDb } from './CustomerDB';
import { IClassCode } from './IClassCode';
import { useAppContext } from '../../shared/context/AppContextProvider';
import Spinner from '../../shared/Spinner/Spinner';
import { FetchMethod } from '../../shared/http/Fetch';
import sendAnalyticsPageViewEvent from '../../shared/analytics/TagManagerHelper';
import PeopleList from './PeopleList';
import CustomerForm from './CustomerForm';

function SpinnerX() {
  return (
    <div style={{
      position: 'absolute', zIndex: 9999, marginLeft: '50%', marginTop: 100,
    }}
    >
      <Spinner />
    </div>
  );
}

// Hook-based form.  Ref blog: https://rangle.io/blog/simplifying-controlled-inputs-with-hooks/
export default function CustomerList2() {
  sendAnalyticsPageViewEvent('CustomerEntryPage');

  const [loading, setLoading] = useState<boolean>(true);
  const [formSubmitTime, setFormSubmitTime] = useState<number>(0);
  const [classCodes, setClassCodes] = useState<Array<IClassCode>>([]);
  const { appConfig, showToastMessage, fetchSecure } = useAppContext();

  function syncMessageReducer(state: Array<string>, action: { type: string, payload: string }) {
    switch (action.type) {
      case 'reset':
        return [];
      case 'add':
        return state.concat(action.payload); // or return [...state, action.payload];
      default:
        throw new Error();
    }
  }
  const [syncresults, syncDispatch] = useReducer(syncMessageReducer, []);

  useEffect(() => {
    const fetchClassCodes = async () => {
      const endpoint = `${appConfig.apiEndpoint}/api/Customer/ClassCodes`;
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('Ocp-Apim-Subscription-Key', '4cfaf252e413414aa09a6148a8083d98');
      const options = {
        method: 'GET',
        headers,
      };
      await fetch(endpoint, options)
        .then((r) => r.json())
        .then((data) => {
          setClassCodes(data);
          setLoading(false);
        })
        .catch((error) => {
          showToastMessage(`ERROR fetching Class Codes:${error}`);
          setLoading(false);
          return error;
        });
    };
    fetchClassCodes();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addToResults = (entry: string) => {
    syncDispatch({ type: 'add', payload: entry });
  };

  const postCustomer = async (data: IPerson): Promise<Response | null> => {
    const result = await fetchSecure(FetchMethod.post, '/api/Customer/Person', data);
    return result;
  };

  const SyncData = async () => {
    syncDispatch({ type: 'reset', payload: '' });
    addToResults("Checking for unsync'd people");

    const syncs = await customerDb.customersNeedSyncd();
    addToResults(`There are ${syncs.length} people to sync`);

    let numberOfSuccess = 0;
    let numberOfError = 0;
    syncs.forEach(async (s, idx) => {
      if (s.id == null) {
        // console.error('Sync error: sync person has a null or undefined id');
        return;
      }
      const person: IPerson | undefined = await customerDb.getCustomerById(s.id);
      if (person === undefined) {
        // console.log('Sync error unable to find person id {s.id} in storage, unable post sync');
        return;
      }
      try {
        // console.log(`synchronizing Person:${JSON.stringify(person)}`);
        const response = await postCustomer(person);
        if (response !== null) {
          // console.log(`response=${JSON.stringify(response));
          if (response.status < 300) {
            await customerDb.setSyncStatus(s.id);
            addToResults(`...successfully posted ${person.id} : ${person.firstname}  ${person.lastname} to Api`);
            numberOfSuccess += 1;
          } else {
            const errtext = await response.text();
            addToResults(`Api failed for ${person?.id} : ${person?.firstname} ${person?.lastname} , returning response=${errtext}`);
            numberOfError += 1;
          }
        }
      } catch (err) {
        addToResults(`Error synchronizing person ${person.id}, err=${JSON.stringify(err)}`);
        numberOfError += 1;
      }
      if (idx === (syncs.length - 1)) {
        showToastMessage(`There were ${numberOfError} errors and ${numberOfSuccess} successful saves.  Please review Sync message for details.`);
        // setFormSubmitTime(new Date().getTime());
      }
    });
  };

  return (
    <>

      <Navbar bg="primary" variant="dark">
        <Button variant="secondary" onClick={SyncData}>Sync Data</Button>
      </Navbar>
      {loading === true ? <SpinnerX /> : null}
      <CustomerForm setFormSubmitTime={setFormSubmitTime} classCodes={classCodes} />
      <PeopleList formSubmitTime={formSubmitTime} classCodes={classCodes} />

      <div>
        <h3>Sync Results</h3>
        <ul>
          {syncresults?.map((sr: string, i: number) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={i}>{sr}</li>
          ))}
        </ul>
      </div>
    </>
  );
}
