import React from 'react';
import './Spinner.css';
import logo from './officemike-logo-tiny.jpg';

function Spinner() {
  return (
    <div className="spinner">
      <span style={{ fontSize: 16 }} role="img" aria-label="Unicorn!~!!!">
        <img src={logo} alt="Logo" />
      </span>
      <span style={{ fontSize: 36 }} role="img" aria-label="Unicorn!~!!!">
        🦄
      </span>
    </div>
  );
}

export default Spinner;
