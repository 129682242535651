import React from 'react';
import './RoutedApp.css';
import Routes from './shared/routes/routes';
import AppHeader from './app/Home/AppHeader';

export function App() {
  return (
    <div className="App">
      <AppHeader />
      <Routes />
    </div>
  );
}

export default App;
