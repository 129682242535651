import React from 'react';
import { Route } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';

export function PrivateRoute({ component, ...rest }:any) {
  const isAuthenticated = useIsAuthenticated();

  const renderFn = (Component:any) => function (props: any) {
    return (
      <>
        {!isAuthenticated
          ? <div>You must be authenticated to see this content!</div>
          : Component !== null
            ? (<Component {...props} />)
            : <div>Something went wrong, PrivateRoute Component is null </div>}
      </>
    );
  };

  return <Route {...rest} render={renderFn(component)} />;
}
