import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import TagManager from 'react-gtm-module';
import { AppInsightsContext, AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './shared/AppInsights/AppInsights';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { AppContextProvider } from './shared/context/AppContextProvider';
import RoutedApp from './RoutedApp';

const tagManagerArgs = {
  gtmId: 'GTM-KQ7JBRL',
};
TagManager.initialize(tagManagerArgs);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function error() {
  return (
    <>
      <h1>ERROR</h1>
      <p>We&apos;re sorry, but something went wrong in the application code.</p>
    </>
  );
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
// https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/5091
// loginPopup() race condition, Popup remains open with app showing in the Popup.  This if condition mitigates but can't use loginRedirect()
if (window.location.hash !== '') {
  // eslint-disable-next-line no-console
  console.log(`hash found: ${window.location.hash}`);
} else {
  root.render(
    <React.StrictMode>
      <AppInsightsErrorBoundary onError={error} appInsights={reactPlugin}>
        <AppInsightsContext.Provider value={reactPlugin}>
          <AppContextProvider>
            <RoutedApp />
          </AppContextProvider>
        </AppInsightsContext.Provider>
      </AppInsightsErrorBoundary>
    </React.StrictMode>,
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
