import React from 'react';
import {
  BrowserRouter as Router, Route, Switch, Link,
} from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import { useIsAuthenticated } from '@azure/msal-react';
import SignInButton from '../forms/SignInButton';
import SignOutButton from '../forms/SignOutButton';
import { PrivateRoute } from './PrivateRoute';
import Home from '../../app/Home/Home';
import Events from '../../app/Events/Events';
import CustomerList from '../../app/Customer/CustomerList';
import CustomerList2 from '../../app/Customer2/CustomerList2';
import { WeatherForecast } from '../../app/WeatherForecast/WeatherForecast';

export default function Routes() {
  const isAuthenticated = useIsAuthenticated();

  return (
    <Router>
      <Navbar bg="dark" variant="dark" expand="lg">
        <Container fluid>
          <Navbar.Brand as={Link} to="/">Events HOME</Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto" navbarScroll>

              <Nav.Item eventkey="1" href="/Events">
                <Nav.Link as={Link} to="/Events">  Events </Nav.Link>
              </Nav.Item>

              <Nav.Item eventkey="2" href="/weatherforecast">
                <Nav.Link as={Link} to="/weatherforecast">  Weather Forecast </Nav.Link>
              </Nav.Item>

              <Nav.Item eventkey="3" href="/customerlist">
                <Nav.Link as={Link} to="/customerlist">  Customers </Nav.Link>
              </Nav.Item>

              <Nav.Item eventkey="4" href="/customerlist2">
                <Nav.Link as={Link} to="/customerlist2">  Customers2 </Nav.Link>
              </Nav.Item>

            </Nav>
            <Nav>
              <div className="d-flex">
                {isAuthenticated ? <SignOutButton /> : <SignInButton />}
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Switch>
        <Route exact path="/" component={Home} />
        <Route
          exact
          path="/Events"
          component={Events}
        />
        <Route
          exact
          path="/customerlist"
          component={CustomerList}
        />
        <Route
          exact
          path="/customerlist2"
          component={CustomerList2}
        />
        <PrivateRoute
          exact
          path="/weatherforecast"
          component={WeatherForecast}
        />
      </Switch>
    </Router>
  );
}
